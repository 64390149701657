<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->


      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none py-0 px-0 d-lg-block"

        >
        <div style="align-items: center; justify-content: center;  display: flex;  width: 100%; height: 100%;  -webkit-align-items: center;">
          <v-img :src="require(`@/assets/images/banner-login.png`)" style="max-width:500px;"></v-img>
        </div>

        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center pa-10 pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"

            >
              <v-card flat>
                <v-card-text>
                  <h1
                    class="text-2xl font-weight-semibold text--primary mb-2"
                  >
                  {{$t('Bienvenidos a la Aplicación Evaluadora de Indicadores de Calidad de Perkins')}}! 👋🏻
                  </h1>
                  <p class="mb-2">
                    {{$t('Ingresa con tu cuenta')}}
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form ref="loginForm" @submit.prevent="loginUser">
                    <v-text-field
                      v-model="form.username"
                      outlined
                      :label="$t('Email o usuario')"
                      :placeholder="$t('Email o usuario')"
                      hide-details="auto"
                      :rules="nameRules"
                      class="mb-6"
                    ></v-text-field>

                    <v-text-field
                      v-model="form.password"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      :label="$t('Contraseña')"
                      :placeholder="$t('Contraseña')"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                      hide-details="auto"
                      class="mb-2"
                      :rules="passwordRules"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>

                    <div
                      class="d-flex align-center justify-space-between flex-wrap"
                    >

                      <!-- forget link -->
                      <a
                        class="ms-3"
                        href="javascript:void(0)"
                        @click="() => {$router.push('/forgotpassword')}"
                      > {{$t('¿Olvidaste tu contraseña?')}}  </a>
                    </div>

                    <v-btn
                      block
                      color="primary"
                      type="submit"
                      class="mt-6"
                      :loading="btnLoading"
                    >
                      {{$t('Iniciar sesión')}}
                    </v-btn>
                  </v-form>
                </v-card-text>

                <!-- create new account  -->
                <v-card-text
                  class="d-flex align-center justify-center flex-wrap mt-2"
                >
                  <!-- <p class="mb-0 me-2">
                    ¿Eres nuevo en la plataforma?
                  </p>
                  <router-link :to="{name:'auth-register'}">
                    Crea una cuenta!
                  </router-link> -->
                  <router-link :to="{name: 'privacy-policies'}">
                    {{$t('Políticas de Privacidad')}}.
                  </router-link>
                </v-card-text>

                <!-- divider -->
                <!-- <v-card-text class="d-flex align-center mt-2">
                  <v-divider></v-divider>
                  <span class="mx-5">ó</span>
                  <v-divider></v-divider>
                </v-card-text> -->

                <!-- socail links -->
                <!-- <v-card-actions class="d-flex justify-center">
                  <v-btn
                    v-for="link in socialLink"
                    :key="link.icon"
                    icon
                    class="ms-1"
                  >
                    <v-icon
                      :color="$vuetify.theme.dark ? link.colorInDark:link.color"
                    >
                      {{ link.icon }}
                    </v-icon>
                  </v-btn>
                </v-card-actions> -->
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <alert-pop-up ref="alert_pop_up"/>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import AlertPopUp from '@/components/AlertPopUp.vue';
import themeConfig from '@themeConfig'
import axios from 'axios'

export default {
  data: () => {
    return {
      form: {
        username: '',
        password: '',
      },
      remember_me: false,
      nameRules: [
        v => !!v || 'El email o usuario es obligatorio',
      ],
      passwordRules: [
        v => !!v || 'El password es obligatorio',
      ],
      loginError: null,
      loging: false,
      btnLoading: false
    }
  },
  methods: {
    async loginUser() {
      if (this.$refs.loginForm.validate()) {
        this.btnLoading = true
        try {
          const response = await this.$api.login(this.form)
          const form = {
            access: response.access,
            refresh: response.refresh,
            remember: this.remember_me,
          }

          const userData =
            await axios.get('api/user_information_profile/', {
              baseURL: process.env.VUE_APP_API_URL,
              headers: {
                'Authorization': `Bearer ${response.access}`,
              }
            })

          // this.loging = false; //enable button
          form.user = userData.data

          this.$store.dispatch('session/setSessionData', form)

            this.$router.push('/home')

        } catch (error) {
          // this.loging = false; //enable button
          if(error?.response?.status === 401) {
            this.$refs.alert_pop_up.showPopUp({ message: this.$t(error?.response?.data.detail), type: 'error' })
          } else {
            this.$refs.alert_pop_up.showPopUp({message: this.$t("Ocurrió un problema, vuelva a intentar más tarde"), type: 'error'})
          }
        } finally {
          this.btnLoading = false
        }

      }

    },
  },
  mounted(){

  },
  components: {
    AlertPopUp,
  },
  setup() {
    const isPasswordVisible = ref(false)

    const email = ref('')
    const password = ref('')

    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    return {
      isPasswordVisible,
      email,
      password,
      socialLink,

      // Icons
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
